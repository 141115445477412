<template>
  <div class="w-100">
    <div class="card">
      <div class="card-header">
        <i class="fas fa-users"></i> Acceso y usuarios
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col md-12">
            <button
              @click="openidModalVersion(false)"
              class="btn btn-outline-primary"
            >
              Nuevo
            </button>
          </div>
          <div class="col-md-12  overflow-auto">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">N.</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Fecha creación</th>
                  <th scope="col">Fecha modificación</th>
                  <th scope="col">Estado</th>
                  <th scope="col">Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) of lstUsuariosAcceso" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>
                    <span>{{ item.usuario.nombreusuario }} </span>
                  </td>
                  <td>
                    <span>{{ item.usuario.username }} </span>
                  </td>
                  <td>{{ formatDate(item.fechaCreacion) }}</td>
                  <td>{{ formatDate(item.fechaModificacion) }}</td>
                  <td>
                    <span
                      v-if="item.estado"
                      class="badge rounded-pill bg-success"
                      >Activo</span
                    >
                    <span
                      v-if="!item.estado"
                      class="badge rounded-pill bg-danger"
                      >Inactivo</span
                    >
                  </td>
                  <td class="min-accion">
                    <button
                      class="btn btn-info m-1"
                      @click="editarVersion(item)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger m-1"
                      @click="eliminarVersion(item)"
                    >
                      <i class="far fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-12">
            <div
              class="modal  fade   "
              id="idModalVersion"
              tabindex="-1"
              aria-labelledby="idModalVersionLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog  modal-dialog-centered  modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="idModalVersionLabel">
                      {{ openEdit ? "Editar" : "Nuevo" }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <FrmUserComponent
                      :itemSelected="dataSelected"
                      :edit="openEdit"
                      v-if="modelRegister!=null"
                    ></FrmUserComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as bootstrap from "bootstrap";
import FrmUserComponent from "../components/FrmUser.vue";
import { Message } from "../config/message.config";
import { mapState, mapActions, mapMutations } from "vuex";
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import axios from "axios";
import config from "../config/app.config";
import { UsuarioSistema } from "../model/usuarioSistema.model";
import { formatString } from "../util/tool.util";
export default {
  name: "access-userId",
  data() {
    return {
      openEdit: false,
      dataSelected: null,
    };
  },

  computed: {
    ...mapState("user", ["lstUsuariosAcceso", "lstUsuarios", "modelRegister"]),
  },
  methods: {
    ...mapMutations(["loadingOpen", "loadingClose"]),
    ...mapActions("user", ["obtenerUsuariosAcceso", "obtenerUsuarios"]),
    ...mapMutations("user", ["setModelRegister"]),
    formatDate(data) {
      return new Date(data).toLocaleString();
    },
    async editarVersion(item) {
      this.openidModalVersion(true, item);
    },
    async openidModalVersion(tipo, data = null) {
      this.setModelRegister(null);
      this.openEdit = tipo;
      if (data == null || tipo == false) data = new UsuarioSistema();
      this.dataSelected = data;
      let modal = new bootstrap.Modal(
        document.getElementById("idModalVersion"),
        {
          keyboard: false,
        }
      );
      modal.show();
      setTimeout(() => {
          this.setModelRegister(modal); 
      }, 150);
  
    },
    async eliminarVersion(item) {
      try {
        const result = await AlertConfirm(
          Message.TITLE_QUESTION_DELETE,
          formatString(
            Message.DESCRIPCION_DELETE_USUARIO_ACCESS,
            item?.usuario?.username
          )
        );
        if (result.isConfirmed) {
          const data = await axios.delete(
            `${config.URL}/userSystemAppUiConfig/eliminar/${item?.idUsuarioSistema}`
          );
          this.loadingOpen();
          if (data.data.statusHttp == 200) {
            await this.obtenerUsuariosAcceso();
          }
          AlertGeneral(data.data);
        }
      } catch (error) {
        AlertError(error);
      }
      this.loadingClose();
    },
  },
  async beforeMount() {
    this.loadingOpen();
    await this.obtenerUsuariosAcceso();
    await this.obtenerUsuarios();
    this.loadingClose();
  },
  components: {
    FrmUserComponent,
  },
};
</script>

<style></style>
