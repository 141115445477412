<template>
  <div class="w-100">
    <form class="row was-validated" @submit="saveFrmData" method="post">
      <div class="col-md-8">
        <div class="row ">
          <div class="col-md-12">
            <label>Usuario</label>
          </div>
          <div class="col-md-12" v-if="!edit">
            <select id="inputGroupSelect01" required v-model="registro.usuario">
              <option selected disabled>Seleccione..</option>
              <option
                v-bind:value="item"
                v-for="(item, index) of lstUsuariosFiltro"
                :key="index"
              >
                {{ item?.username }} | {{ item.nombreusuario }}
                {{ item.apellidos }}
              </option>
            </select>
          </div>
          <div class="col-md-12" v-if="edit">
            {{ registro?.usuario?.username }} |
            {{ registro?.usuario?.nombreusuario }}
            {{ registro?.usuario?.apellidos }}
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex justify-content-center align-items-center">
        <div class="form-check form-switch">
          <input
            class="form-check-input "
            type="checkbox"
            id="flexSwitchCheckDefault"
            v-model="registro.estado"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault"
            >Estado</label
          >
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-center align-items-center">
        <button type="submit" class="btn btn-outline-success">
          Guardar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { AlertGeneral, AlertError, AlertConfirm } from "../util/alert.util";
import axios from "axios";
import config from "../config/app.config";
import Selectr from "mobius1-selectr";
export default {
  name: "frmUserName",
  props: {
    itemSelected: null,
    edit: false,
  },
  data() {
    return {
      registro: {},
      selectedUi: null,
    };
  },
  watch: {
    itemSelected: function(newData, oldData) {},
  },
  computed: {
    ...mapState("user", ["modelRegister", "lstUsuarios", "lstUsuariosAcceso"]),
    lstUsuariosFiltro() {
      let lstUsuariosFiltro = [];
      this.lstUsuarios.forEach((x) => {
        let existe = this.lstUsuariosAcceso.find(
          (u) => u.usuario.username == x.username
        );
        if (existe == null) {
          lstUsuariosFiltro.push(x);
        }
      });
      return lstUsuariosFiltro;
    },
  },
  mounted() {
    if (this.itemSelected != null) {
      this.registro = this.itemSelected;
      if (this.itemSelected != null && this.edit)
        this.registro = JSON.parse(JSON.stringify(this.itemSelected));
      else {
      }
      if (!this.edit)
        new Selectr(document.getElementById("inputGroupSelect01"));
    }
   // console.log(this.itemSelected);
  },
  methods: {
    ...mapMutations(["loadingOpen", "loadingClose"]),
    ...mapActions("user", ["obtenerUsuariosAcceso", "obtenerUsuarios"]),
    async saveFrmData(e) {
      e.preventDefault();
      let item = JSON.parse(JSON.stringify(this.registro));
      this.loadingOpen();
      try {
        let url = `${config.URL}/userSystemAppUiConfig/`;
        let data = null;
        if (this.edit) {
          url = `${url}actualizar`;
          item.fechaModificacion = new Date();
          data = await axios.put(url, item);
        } else {
          url = `${url}insertar`;
          data = await axios.post(url, item);
        }

        if (data.data.statusHttp == 200) {
          this.modelRegister?.hide();
          await this.obtenerUsuariosAcceso();
        }
        AlertGeneral(data.data);
      } catch (error) {
        console.log(error);
        AlertError(error);
      }

      this.loadingClose();
    },
  },
};
</script>

<style></style>
